import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { HeaderMiddle, HeaderTop, HeaderWrap, Nav } from "./index.styled";

import { deleteToken } from "@/utils/token";
import Text from "@/components/elements/Text";
import { useNavigateSearch } from "@/hooks/useNavigateSearch";

type NavType = {
  title: string;
  route: string;
  key: string;
};

const Header = () => {
  const location = useLocation();
  const { navigateSearch } = useNavigateSearch();
  const path: string = useLocation().pathname.split("/")[1];
  const pathName: string = path === "" ? "home" : path;

  const [nav, setNav] = useState<NavType[]>([
    { title: "대시보드", route: "/", key: "home" },
    { title: "퍼널분석", route: "/funnel", key: "funnel" },
    { title: "어드민", route: "/admin/store", key: "admin" },
    { title: "정산 조정", route: "/settlement", key: "settlement" },
  ]);

  const handleLogout = (): void => {
    deleteToken("ep_tk");
    navigateSearch("/login");
  };

  useEffect((): void => {
    const selectNavBar: NavType[] = nav.map(page => {
      if (pathName === page.key) return { ...page, active: true };
      return { ...page, active: false };
    });

    setNav([...selectNavBar]);
  }, [location.search]);

  return (
    <HeaderWrap>
      <HeaderTop>
        <Text>EARLYPAY | DASHBOARD</Text>
        <div onClick={handleLogout}>
          <Text>로그아웃</Text>
        </div>
      </HeaderTop>
      <HeaderMiddle _row>
        {nav.map(x => (
          <Nav _active={x.key === pathName} href={x.route} key={x.title}>
            {x.title}
          </Nav>
        ))}
      </HeaderMiddle>
    </HeaderWrap>
  );
};

export default Header;
