import { ScrollWrapper, Wrapper } from "./index.styled";
import { useGetDepositOverview } from "@/apis/deposit/getDepositOverview";
import { useGetStoreDetail } from "@/apis/stores/getStoreDetail";
import Text from "@/components/elements/Text";
import DepositDetailModal from "@/components/features/settlement/DepositDetailModal";
import DepositOverviewList from "@/components/features/settlement/DepositOverviewList";
import StoreInfoBox from "@/components/features/settlement/StoreInfoBox";
import SearchDetailBox from "@/components/features/settlementDetail/SearchDetailBox";
import { selectedDepositDateState } from "@/recoil/deposit/atoms";
import { Breadcrumb, BreadcrumbItem } from "@carbon/react";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router";
import { useSetRecoilState } from "recoil";

const Detail = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const [isVisibleDepositModal, setIsVisibleDepositsModal] = useState<boolean>(false);
  const setSelectedDepositDate = useSetRecoilState(selectedDepositDateState);

  const pathname = useLocation().pathname.split("/");
  const storeId = Number(pathname.pop());
  const searchParams = new URLSearchParams(location.search);
  const startDate = searchParams.get("start_date");
  const endDate = searchParams.get("end_date");

  const { data: storeDetailData, isLoading } = useGetStoreDetail(storeId);
  const { data: depositOverviewData } = useGetDepositOverview(storeId, startDate, endDate);

  useEffect(() => {
    queryClient.invalidateQueries("StoreDetail", { refetchActive: true });
  }, [location]);

  const onOpenDepositModal = (date: string) => {
    setIsVisibleDepositsModal(true);
    setSelectedDepositDate(date);
  };

  const onCloseDepositModal = () => {
    setIsVisibleDepositsModal(false);
  };

  if (isLoading) {
    return;
  }

  if (!storeDetailData) {
    return;
  }

  return (
    <Wrapper>
      {isVisibleDepositModal && (
        <DepositDetailModal
          onClose={onCloseDepositModal}
          isVisible={isVisibleDepositModal}
          storeDetailData={storeDetailData}
        />
      )}

      <Breadcrumb noTrailingSlash={true}>
        <BreadcrumbItem href="/settlement">정산 조정</BreadcrumbItem>
        <BreadcrumbItem href="#">{`${storeDetailData.title}(${storeDetailData.user.name})`}</BreadcrumbItem>
      </Breadcrumb>

      <Text size={24} bold>
        정산 조정
      </Text>

      {/** 검색 + 회수날짜 필터링 Section */}
      <SearchDetailBox />

      <ScrollWrapper>
        {/** 가게 정보 Section */}
        <StoreInfoBox items={storeDetailData} />

        {/** 입출금 내역 Section */}
        {depositOverviewData && (
          <DepositOverviewList items={depositOverviewData.results} onClickDate={onOpenDepositModal} />
        )}
      </ScrollWrapper>
    </Wrapper>
  );
};

export default Detail;
