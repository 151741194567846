import Text from "@/components/elements/Text";
import { getDate } from "@/utils/getFormattedDate";
import { CopiedAccountWrapper } from "@/components/features/settlement/StoreInfoBox/index.styled";
import { CopiedAccount } from "@/components/features/settlement/CopiedAccount";
import { Stack } from "@carbon/react";
import React from "react";
import { StoreDetailProps } from "@/types/storeType";

export interface UserAccountList {
  items: StoreDetailProps;
}

export const UserAccountList: React.FC<UserAccountList> = ({ items }) => {
  const baminAccount = items.deliveryAccounts.find(data => data.platform === "BAEMIN");
  const yogiyoAccount = items.deliveryAccounts.find(data => data.platform === "YOGIYO");
  const coupangAccount = items.deliveryAccounts.find(data => data.platform === "COUPANG");

  return (
    <Stack gap="12px">
      <Text>{`선정산 시작일: ${getDate(items.firstEarlypayDate)}`}</Text>
      <CopiedAccountWrapper>
        <Text>CFA</Text>
        <CopiedAccount type="CFA" label="ID" value={items.cfaAccount} />
        <CopiedAccount type="" label="연락처" value={items.user.phone} />
      </CopiedAccountWrapper>
      {baminAccount && (
        <CopiedAccountWrapper>
          <Text>배달의민족</Text>
          <CopiedAccount type="배달의민족" label="ID" value={baminAccount.identification} />
          <CopiedAccount type="배달의민족" label="PW" value={baminAccount.password} isSecret />
        </CopiedAccountWrapper>
      )}
      {yogiyoAccount && (
        <CopiedAccountWrapper>
          <Text>요기요</Text>
          <CopiedAccount type="요기요" label="ID" value={yogiyoAccount.identification} />
          <CopiedAccount type="요기요" label="PW" value={yogiyoAccount.password} isSecret />
        </CopiedAccountWrapper>
      )}
      {coupangAccount && (
        <CopiedAccountWrapper>
          <Text>쿠팡이츠</Text>
          <CopiedAccount type="쿠팡이츠" label="ID" value={coupangAccount.identification} />
          <CopiedAccount type="쿠팡이츠" label="PW" value={coupangAccount.password} isSecret />
        </CopiedAccountWrapper>
      )}
    </Stack>
  );
};

export default UserAccountList;
